<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="7" :md="7" :sm="24" >
          <a-form-item label="所属部门"  :label-col="{ span: 8 }"  :wrapper-col="wrapperCol">
          {{ this.departmentName}}
          </a-form-item>
        </a-col>
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item label="交接人"  :label-col="labelCol"  :wrapper-col="wrapperCol">
            {{ this.formItem.handoverUsernames }}
          </a-form-item>
        </a-col>
        <a-col :lg="10" :md="10" :sm="24">
          <a-form-item label="交班时间"  :label-col="labelCol"  :wrapper-col="wrapperCol">
            {{formatHandOverDate()}}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item label="进水量"  :label-col="labelCol"  :wrapper-col="wrapperCol">
            {{ this.formItem.inWaterVolume }}
          </a-form-item>
        </a-col>
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item label="出水量"  :label-col="labelCol"  :wrapper-col="wrapperCol">
            {{ this.formItem.outWaterVolume }}
          </a-form-item>
        </a-col>
        <a-col :lg="10" :md="10" :sm="24">
          <a-form-item label="累计出水总量"  :label-col="{ span: 8 }" :wrapper-col="wrapperCol">
            {{ this.formItem.totalOutWaterVolume }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="机电、管网运行情况">
            {{ this.formItem.electricalPipeDesc }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="仪器、仪表运行情况">
            {{ this.formItem.instrumentDesc }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="电话接听情况">
            {{ this.formItem.phoneDesc }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="清运情况">
            {{ this.formItem.cleanUpDesc }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="遗留问题，处理结果描述">
            {{ this.formItem.problemHandleResultDesc }}
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="接班人"  layout="vertical"  :label-col=" { span: 2 }" :wrapper-col="wrapperCol"  labelAlign="left" >
            {{ this.formItem.succeedUsernames }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="交接班意见">
            {{ this.formItem.succeedOpinion }}
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '../../api/service.url'
  import { DutyDTO } from '../../api/vo/duty/dutyDomain'
  import moment from 'moment'

  export default {
    name: 'DutyDetail',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data() {
      return {
        spinShow: false,
        organizations: [],
        formItem: DutyDTO(),
        handoverUserIds: [],
        succeedUserIds: [],
        orgTree: [],
        userTreeData: [],
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
        departmentName: '',
      }
    },
    mounted() {
      this.loadOrgTree()
    },
    methods: {
      moment,
      setFields(body) {
        Object.assign(this.formItem, body)
        this.departmentName = this.getSelectOrgName(this.orgTree, this.formItem.departmentId)
      },
      loadData(id) {
        this.formItem.id = id
        this.$http(this, {
          url: SERVICE_URLS.duty.info.view,
          params: { id },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.setFields(data.body)
          }
        })
      },
      loadOrgTree() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
            if (this.formItem.departmentId) {
              this.departmentName = this.getSelectOrgName(this.orgTree, this.formItem.departmentId)
            }
          }
        })
      },
      getSelectOrgName(list, id) {
        var title
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          if (item.id === id) {
            title = item.title
            return title
          } else if (item.children.length > 0) {
            title = this.getSelectOrgName(item.children, id)
          }
          if (title) return title
        }
      },
      formatHandOverDate() {
        if (this.formItem.handoverDate) {
          return moment().format('YYYY-MM-DD HH:mm:ss')
        }
        return ''
      }
    }
  }
</script>