<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">交接班</h1>
      <!--      <h1 class="title"  v-else>接班</h1>-->
    </div>
    <div slot="extra">
      <div class="more-info">
        <a-button
          class="m-l-12"
          icon="plus"
          type="primary"
          @click="handleAdd">
          交接班添加
        </a-button>
      </div>
    </div>
    <!-- ↓↓↓表格↓↓↓ -->
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="交班人">
            <a-input v-model="searchParams.handoverUsername" placeholder="请输入交班人名称" allowClear/>
          </a-form-item>
          <a-form-item label="接班人">
            <a-input v-model="searchParams.succeedUsername" placeholder="请输入接班人名称" allowClear/>
          </a-form-item>
          <a-form-item label="所属部门">
            <a-tree-select
              style="width: 210px;"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="orgTree"
              allowClear
              placeholder="请选择部门"
              v-model="searchParams.departmentId"
            ></a-tree-select>
          </a-form-item>
          <a-form-item label="交班时间">
            <a-range-picker @change="dateChange" v-model="dateSearch">
              <a-icon slot="suffixIcon" type="calendar"/>
            </a-range-picker>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" :loading="tableLoading" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>

      <a-table
        :rowKey="(record) => record.id"
        :customRow="handleView"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.page * searchParams.size + index + 1 }}
        </template>
        <template slot="operation" slot-scope="text, record">
          <a v-if="record.succeedUsernames === null" @click.stop="handleShiftEdit(record.id)">接班</a>
          <a-divider type="vertical" v-if=" record.succeedUsernames === null"/>
          <a @click.stop="handleEdit(record.id)">编辑</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleDelete(record.id, '信息删除后不可恢复。')">删除</a>
        </template>
      </a-table>
    </a-card>
    <a-modal
      title="添加"
      v-model="addModal"
      :maskClosable="false"
      okText="添加"
      cancelText="取消"
      width="1060px"
      :confirmLoading="confirmButtonStatus"
      @ok="handleAddOk"
      visible="listType === 'handover'">
      <duty-form
        type="add"
        ref="addRef"
        @addSuccess="addSuccess"
        @addError="addError"
        @submitStart="handleConfirmStart"
      ></duty-form>
    </a-modal>
    <a-modal
      title="交接班修改"
      v-model="editModal"
      :maskClosable="false"
      okText="修改"
      cancelText="取消"
      width="1060px"
      :confirmLoading="confirmButtonStatus"
      @ok="handleEditOk"
    >
      <duty-form
        type="edit"
        ref="editRef"
        @editSuccess="editSuccess"
        @editError="editError"
        @submitStart="handleConfirmStart"
      ></duty-form>
    </a-modal>
    <a-modal
      title="接班修改"
      v-model="shiftModal"
      :maskClosable="false"
      okText="保存"
      cancelText="取消"
      width="1060px"
      :confirmLoading="confirmButtonStatus"
      @ok="handleShiftEditOk"
    >
      <duty-form
        type="shiftEdit"
        ref="editsShiftRef"
        @editSuccess="editShiftSuccess"
        @editError="editError"
        @submitStart="handleConfirmStart"
      ></duty-form>
    </a-modal>
    <a-drawer
      title="交接班详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      :width="800"
      :visible="detailDrawer"
      :mask="false"
    >
      <DutyDetail ref="viewRef"></DutyDetail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '../../api/service.url'
  import entityCRUD from '../common/mixins/entityCRUD'
  import PageLayout from '@/components/page/PageLayout'
  import moment from 'moment'
  import DutyDetail from './DutyDetail'

  import DutyForm from './DutyForm'

  export default {
    /*props: {
      listType: {}
    },*/
    name: 'DutyList',
    components: { PageLayout, DutyForm, DutyDetail },
    mixins: [entityCRUD],
    data() {
      return {
        shiftModal: false,
        entityBaseUrl: SERVICE_URLS.duty.info,
        searchParams: {
          page: 0,
          size: 10,
          direction: '',
          order: '',
          succeedUsername: '',
          handoverUsername: '',
          departmentId: ''
        },
        orgTree: [],
        initColumns: [
          {
            title: '序号',
            align: 'center',
            width: 70,
            scopedSlots: { customRender: '_index' }
          },
          {
            title: '交班人',
            dataIndex: 'handoverUsernames',
            align: 'center'
          },
          {
            title: '接班人',
            dataIndex: 'succeedUsernames',
            align: 'center'
          },
          {
            title: '部门',
            dataIndex: 'departmentId',
            align: 'center',
            customRender: (text, record) => {
              if (this.orgMap[record.departmentId]) {
                return this.orgMap[record.departmentId].title
              }
              return record.departmentId
            }
          },
          {
            title: '交接时间',
            dataIndex: 'handoverDate',
            align: 'center',
            width: '200px',
            customRender: (text, record) => {
              var date = record.handoverDate
              if (date === undefined) {
                return ''
              }
              return moment(date).format('YYYY-MM-DD HH:mm:ss')
            }
          },
          {
            title: '电话接听情况',
            dataIndex: 'phoneDesc',
            align: 'left',
            width: '280px',
            ellipsis: true
          },
          {
            title: '清运情况',
            dataIndex: 'cleanUpDesc',
            align: 'left',
            width: '280px',
            ellipsis: true
          },
          {
            title: '遗留问题，处理结果描述',
            dataIndex: 'problemHandleResultDesc',
            align: 'left',
            width: '280px',
            ellipsis: true
          },
          {
            title: '操作',
            dataIndex: 'operation',
            align: 'center',
            width: 150,
            scopedSlots: { customRender: 'operation' }
          }
        ],
        chooseData: {},
        orgMap: {},
        dateSearch: null, //日期绑定数据
        detailDrawer: false,
        listType: 'handover'
      }
    },
    /*watch: {
      listType(val) {
      }
    },*/
    created() {
      this.loadOrgTree()
    },
    methods: {
      loadOrgTree() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
            this.initOrgMap(this.orgTree)
          }
        })
      },
      initOrgMap(orgTree) {
        orgTree.forEach((item) => {
          this.orgMap[item.id] = item
          this.initOrgMap(item.children)
        })
      },
      dateChange(date, dateString) {
        this.searchParams.startTimeStr = dateString[0]
        this.searchParams.endTimeStr = dateString[1]
      },
      resetForm() {
        this.searchParams.succeedUsername = ''
        this.searchParams.handoverUsername = ''
        this.searchParams.departmentId = ''
        this.searchParams.startTimeStr = ''
        this.searchParams.endTimeStr = ''
        this.dateSearch = null
      },
      handleAdd() {
        this.addModal = true
      },
      addSuccess() {
        // this.addLoading = false
        this.confirmButtonStatus = false
        this.addModal = false
        this.listType = 'shift'
        this.search()
        // this.$message.success('创建成功！')
      },
      handleShiftEdit(id) {
        this.shiftModal = true
        this.$nextTick(() => {
          if (this.$refs['editsShiftRef']) {
            if (
              typeof this.$refs['editsShiftRef'].loadData !== 'undefined' &&
              this.$refs['editsShiftRef'].loadData instanceof Function
            ) {
              this.$refs['editsShiftRef'].loadData(id)
            } else {
              console.error('Form子组件内部未定义loadData方法')
            }
          } else {
            console.error('editsShiftRef')
          }
        })
      },
      handleShiftEditOk() {
        if (this.$refs['editsShiftRef']) {
          if (
            typeof this.$refs['editsShiftRef'].submitEdit !== 'undefined' &&
            this.$refs['editsShiftRef'].submitEdit instanceof Function
          ) {
            this.$refs['editsShiftRef'].submitEdit()
          } else {
            console.error('Form子组件内部未定义submitEdit方法')
          }
        } else {
          console.error('Form子组件的editRef未指定')
        }
      },
      editShiftSuccess() {
        this.confirmButtonStatus = false
        this.shiftModal = false
        this.loadTableData()
        this.listType = 'handover'
      },
      search() {
        this.$http(this, {
          url: this.searchUrl,
          noTips: true,
          data: this.searchParams,
          loading: 'tableLoading',
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.number = data.body.number
          }
        })
      },
      handleView(record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      }
    }
  }
</script>
