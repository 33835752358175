<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="7" :md="7" :sm="24" >
          <a-form-item label="所属部门"  :label-col="labelCol"  :wrapper-col="wrapperCol"  v-if="type === 'shiftEdit'">
            {{ this.departmentName}}
          </a-form-item>
          <a-form-item label="所属部门"  :label-col="labelCol"  :wrapper-col="wrapperCol" v-else>
            <a-tree-select   :treeData="orgTree"  v-decorator="['departmentId',{rules: [{required: true, message: '请选择组织机构'}]}]"  allowClear  placeholder="请选择部门"   style="width: 100%"></a-tree-select>
          </a-form-item>
        </a-col>
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item label="交接人"  :label-col="labelCol"  :wrapper-col="wrapperCol" v-if="type === 'shiftEdit'">
            {{ this.formItem.handoverUsernames }}
          </a-form-item>
          <a-form-item label="交接人"  :label-col="labelCol"  :wrapper-col="wrapperCol" v-else>
            <a-tree-select   :treeData="userTreeData"  v-decorator="[`handoverUserIds`, {validateTrigger: ['change'], rules: [{required: true, message: '请选择交接人'}]}]" multiple style="width: 100%" placeholder="请选择交接人"/>
          </a-form-item>
        </a-col>
        <a-col :lg="10" :md="10" :sm="24">
          <a-form-item label="交班时间"  :label-col="labelCol"  :wrapper-col="wrapperCol" v-if="type === 'shiftEdit'">
            {{formatHandOverDate()}}
          </a-form-item>
          <a-form-item label="交班时间"  :label-col="labelCol"  :wrapper-col="wrapperCol" v-else>
            <a-date-picker format="YYYY-MM-DD HH:mm"  style="width: 100%"  :showTime="{ format: 'HH:mm' }" v-decorator="['handoverDate', {initialValue:  moment(new Date()).format('YYYY-MM-DD HH:mm'),  rules: [{required: true, message: '请选择组织机构'}]}]"   placeholder="请选择交班时间"></a-date-picker>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item label="进水量"  :label-col="labelCol"  :wrapper-col="wrapperCol" v-if="type === 'shiftEdit'">
            {{ this.formItem.inWaterVolume }}
          </a-form-item>
          <a-form-item label="进水量"  :label-col="labelCol"  :wrapper-col="wrapperCol"  v-else>
            <a-input  placeholder="输入进水量"  style="width: 100%"  v-decorator="['inWaterVolume',{initialValue:0}]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item label="出水量"  :label-col="labelCol"  :wrapper-col="wrapperCol" v-if="type === 'shiftEdit'">
            {{ this.formItem.outWaterVolume }}
          </a-form-item>
          <a-form-item label="出水量"  :label-col="labelCol"  :wrapper-col="wrapperCol" v-else>
            <a-input   placeholder="输入出水量"  style="width: 100%" v-decorator="['outWaterVolume',{initialValue:0}]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="10" :md="10" :sm="24">
          <a-form-item label="累计出水总量"  :label-col="labelCol" :wrapper-col="wrapperCol" v-if="type === 'shiftEdit'">
            {{ this.formItem.totalOutWaterVolume }}
          </a-form-item>
          <a-form-item label="累计出水总量" :label-col="labelCol" :wrapper-col="wrapperCol" v-else>
            <a-input   placeholder="输入累计出水总量"  style="width: 100%"  v-decorator="['totalOutWaterVolume',{initialValue:0}]"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="机电、管网运行情况" v-if="type === 'shiftEdit'">
            {{ this.formItem.electricalPipeDesc }}
          </a-form-item>
          <a-form-item label="机电、管网运行情况" v-else>
            <a-textarea v-decorator="['electricalPipeDesc']" rows="2" placeholder="请输机电、管网运行情况"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="仪器、仪表运行情况"  v-if="type === 'shiftEdit'">
            {{ this.formItem.instrumentDesc }}
          </a-form-item>
          <a-form-item label="仪器、仪表运行情况"  v-else>
            <a-textarea v-decorator="['instrumentDesc']" rows="2" placeholder="请输入仪器、仪表运行情况"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="电话接听情况" v-if="type === 'shiftEdit'">
          {{ this.formItem.phoneDesc }}
          </a-form-item>
          <a-form-item label="电话接听情况"  v-else>
            <a-textarea v-decorator="['phoneDesc']" rows="2" placeholder="请输入电话接听情况"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="清运情况" v-if="type === 'shiftEdit'">
            {{ this.formItem.cleanUpDesc }}
          </a-form-item>
          <a-form-item label="清运情况" v-else>
            <a-textarea v-decorator="['cleanUpDesc']" rows="2" placeholder="请输入清运情况"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="遗留问题，处理结果描述" v-if="type === 'shiftEdit'">
            {{ this.formItem.problemHandleResultDesc }}
          </a-form-item>
          <a-form-item label="遗留问题，处理结果描述" v-else>
            <a-textarea v-decorator="['problemHandleResultDesc']" rows="2" placeholder="请输入遗留问题，处理结果描述"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row" :gutter="16" v-if="type === 'shiftEdit'">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="接班人"  layout="vertical"  :label-col=" { span: 2 }" :wrapper-col="wrapperCol"  labelAlign="left" v-if="type === 'shiftEdit'">
            <a-tree-select   :treeData="userTreeData"  v-decorator="[`succeedUserIds`, {rules: [{required: true, message: '请选择接班人'}]}]" labelAlign="left" multiple style="width: 100%" placeholder="请选择交接人"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="交接班意见" v-if="type === 'shiftEdit'">
            <a-textarea v-decorator="['succeedOpinion',{rules: [{required: true, message: '请选择接班人'}]}]" rows="2" placeholder="请输入交接班意见"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '../../api/service.url'

  import orgValidator from './mixins/org-validator'
  import { DutyDTO } from '../../api/vo/duty/dutyDomain'
  import moment from 'moment'

  export default {
    name: 'DutyForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    mixins: [orgValidator],
    data() {
      return {
        spinShow: false,
        organizations: [],
        formItem: DutyDTO(),
        handoverUserIds: [],
        succeedUserIds: [],
        orgTree: [],
        userTreeData: [],
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
        departmentName: '',
      }
    },
    beforeCreate() {
      this.form = this.$form.createForm(this)
      this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
    },
    mounted() {
      this.LoadUserList()
      this.loadOrgTree()
    },
    methods: {
      moment,
      submitAdd() {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.formItem.id = null
            this.setDTOFields(values)
            this.$emit('submitStart')
            this.$http(this, {
              url: SERVICE_URLS.duty.info.handover,
              data: this.formItem,
              loading: 'spinShow',
              success: (data) => {
                //清空表单数据
                this.formItem = DutyDTO()
                this.form.resetFields()
                this.$emit('addSuccess', data.body)
              },
              error: () => {
                this.$emit('addError')
              }
            })
          }
        })
      },
      submitEdit() {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setDTOFields(values)
            this.$emit('submitStart')
            var httpUrl = SERVICE_URLS.duty.info.update
            var httpData = this.formItem
            if (this.type === 'shiftEdit') {
              httpUrl = SERVICE_URLS.duty.info.succeed
              httpData = { id: this.formItem.id, succeedUserIds: this.formItem.succeedUserIds, succeedOpinion: this.formItem.succeedOpinion, succeedTeamId: 0 }
            }
            this.$http(this, {
              url: httpUrl,
              data: httpData,
              loading: 'spinShow',
              success: (data) => {
                this.$emit('editSuccess', data.body)
              },
              error: () => {
                this.$emit('editError')
              }
            })
          } else {
            console.log(err)
          }
        })
      },
      setDTOFields(values) {
        this.formItem.electricalPipeDesc = values.electricalPipeDesc
        this.formItem.instrumentDesc = values.instrumentDesc
        this.formItem.phoneDesc = values.phoneDesc
        this.formItem.cleanUpDesc = values.cleanUpDesc
        this.formItem.problemHandleResultDesc = values.problemHandleResultDesc
        this.formItem.succeedOpinion = values.succeedOpinion
        this.formItem.inWaterVolume = values.inWaterVolume
        this.formItem.outWaterVolume = values.outWaterVolume
        this.formItem.totalOutWaterVolume = values.totalOutWaterVolume
        this.formItem.handoverUserIds = []
        if (values.handoverUserIds) {
            values.handoverUserIds.forEach((item) => {
            var aa = item.split('_')
            this.formItem.handoverUserIds.push(aa[aa.length - 1])
          })
        }
        this.formItem.handoverDate = values.handoverDate
        this.formItem.departmentId = values.departmentId
        this.formItem.succeedUserIds = []
        if (values.succeedUserIds) {
          values.succeedUserIds.forEach((item) => {
            var aa = item.split('_')
            this.formItem.succeedUserIds.push(aa[aa.length - 1])
          })
        }
      },
      setFields(body) {
        Object.assign(this.formItem, body)
        this.departmentName = this.getSelectOrgName(this.orgTree, this.formItem.departmentId)
        if (this.type !== 'shiftEdit') {
          this.initSelectedField()
          this.form.setFieldsValue({
            electricalPipeDesc: this.formItem.electricalPipeDesc,
            instrumentDesc: this.formItem.instrumentDesc,
            phoneDesc: this.formItem.phoneDesc,
            cleanUpDesc: this.formItem.cleanUpDesc,
            problemHandleResultDesc: this.formItem.problemHandleResultDesc,
            inWaterVolume: this.formItem.inWaterVolume,
            outWaterVolume: this.formItem.outWaterVolume,
            totalOutWaterVolume: this.formItem.totalOutWaterVolume,
            handoverDate: moment(this.formItem.handoverDate).format('YYYY-MM-DD HH:mm:ss'),
            departmentId: this.formItem.departmentId,
            succeedOpinion: this.formItem.succeedOpinion,
            succeedUserIds: this.formItem.succeedUserIds,
          })
        }
      },
      getSelectKey(list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
      loadData(id) {
        this.formItem.id = id
        this.$http(this, {
          url: SERVICE_URLS.duty.info.view,
          params: { id },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.setFields(data.body)
          }
        })
      },
      initSelectedField() {

        if (this.formItem.handoverUserIds) {
          const tempList = []
          this.formItem.handoverUserIds.forEach((item) => {
            const key = this.getSelectKey(this.userTreeData, item)
            if (key !== undefined) tempList.push(key)
          })
          if (tempList.length === this.formItem.handoverUserIds.length) {
            this.formItem.handoverUserIds = tempList
          }
          this.form.setFieldsValue({
            handoverUserIds: this.formItem.handoverUserIds
          })
        }
        if (this.formItem.succeedUserIds) {
          const tempList1 = []
          this.formItem.succeedUserIds.forEach((item) => {
            const key = this.getSelectKey(this.userTreeData, item)
            if (key !== undefined) tempList1.push(key)
          })
          if (tempList1.length === this.formItem.succeedUserIds.length) {
            this.formItem.succeedUserIds = tempList1
          }
          this.form.setFieldsValue({
            succeedUserIds: this.formItem.succeedUserIds
          })
        }
      },
      LoadUserList() {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'org_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userTreeData = data.body
            this.initSelectedField()
          }
        })
      },
      loadOrgTree() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
            if (this.formItem.departmentId) {
               this.departmentName = this.getSelectOrgName(this.orgTree, this.formItem.departmentId)
            }
          }
        })
      },
      getSelectOrgName(list, id) {
        var title
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          if (item.id === id) {
            title = item.title
            return title
          } else if (item.children.length > 0) {
            title = this.getSelectOrgName(item.children, id)
          }
          if (title) return title
        }
      },
      formatHandOverDate() {
        if (this.formItem.handoverDate) {
          return moment().format('YYYY-MM-DD HH:mm:ss')
        }
       return ''
      },
      onChangeUser () {
        this.searchParams.dutyUserId = this.userStr ? this.userStr.split('_')[2] : undefined
      }
    }
  }
</script>
