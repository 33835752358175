export const DutyDTO = function () {
  return {
    id: null,
    electricalPipeDesc: '', //机电、管网运行情况
    instrumentDesc: '', //仪器、仪表运行情况
    phoneDesc: '', //电话接听情况
    cleanUpDesc: '', //清运情况
    problemHandleResultDesc: '', //遗留问题，处理结果描述
    inWaterVolume: 0, //进水量
    outWaterVolume: 0, //出水量
    totalOutWaterVolume: 0, //累计出水量
    handoverUserIds: [], //交班人
    handoverDate: null, //交班时间
    departmentId: '', //所属部门
    handoverTeamId: 1,
  }
}

// export const DutyDetailDTO = function () {
//   return {
//     //   id: null,
//     electricalPipeDesc: '', //机电、管网运行情况
//     instrumentDesc: '', //仪器、仪表运行情况
//     phoneDesc: '', //电话接听情况
//     cleanUpDesc: '', //清运情况
//     problemHandleResultDesc: '', //遗留问题，处理结果描述
//     inWaterVolume: 0, //进水量
//     outWaterVolume: 0, //出水量
//     totalOutWaterVolume: 0, //累计出水量
//     handoverUserIds: [], //交班人
//     handoverDate: null, //交班时间
//     departmentId: '', //所属部门
//     handoverTeamId: 1,
//   }
// }